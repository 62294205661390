/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CropTypes } from "./chemicals";
import { ICompany } from "./company";
import { IGasAnalysis } from "./gasAnalysis";
import { IPredictionAiModel } from "./predictionModel";
import { ISpectraItem } from "./spectra";
import { IUser } from "./user";

export enum SampleGroupType {
  UploadLog = "Upload Log",
  DownloadLog = "Download Log",
  Collection = "Collection",
}

export enum SampleRecordManualUpdateReason {
  ManualInput = "Initial Manual Input",
  ImprovedAnalysis = "Improved Analysis",
  FixEntryError = "Fix Entry Error",
  Other = "Other",
}

export interface ISampleRecordManualUpdate {
  reason?: string;
  updatedBy: { fullName: string; companyName: string; userId: string };
  updatedAt: Date;
  updatedFields: {
    isChemical?: boolean;
    fieldName: string;
    oldValue: any;
    newValue: any;
  }[];
}
export interface ISampleRecordGroup {
  _id?: string;
  name: string;
  description?: string;
  uids: number[];
  companyId?: string;
  type?: SampleGroupType;
  createdAt?: Date;
  actionBy?: IUser;
  company?: ICompany;
}
export interface ISampleRecordBaseFields {
  product?: string;
  application?: string;
  companyUid?: number;
  sampleNumber?: string;
  sampleSource?: string;
  sampleClient?: string;
  description?: string;
  reportType?: string;
  bagWeight?: number;
  dataset?: string;
  country?: string;
  region?: string;
  year?: number;
  type?: CropTypes;
  chemicals?: { [key: string]: number | undefined };
  spectraItems?: ISpectraItem[];
  spectraData?: string;
  sampleTakenDate?: string;
  scannerCalibrationFiles?: string[];
  manualUpdates?: ISampleRecordManualUpdate[];
  predictedType?: CropTypes;
}

export interface ISampleRecordCreationPayload extends ISampleRecordBaseFields {
  temporaryId?: number;
  predictionAiModel?: IPredictionAiModel;
  // ...base fields here
}

export interface ISampleRecordPopulated extends ISampleRecordBaseFields {
  _id?: string;
  uid: number;
  company?: ICompany;
  vendorCompany?: ICompany;
  // ...base fields here
  createdAt?: Date;
  gasAnalysis?: IGasAnalysis;
  predictionAiModel?: IPredictionAiModel;
}

export interface IArchivedPrediction {
  product?: string;
  type?: string;
  sampleNumber: string;
  chemicals?: { [key: string]: number };
  spectraItems?: ISpectraItem[];
  predictionAiModel?: IPredictionAiModel;
  company?: ICompany;
  createdAt?: Date;
}
