/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { ISampleRecordPopulated } from "../../../../../types/sample";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { Grid, Stack, TableCell } from "@mui/material";
import {
  getAcetate,
  getPropionate,
  getTotalVFA,
} from "../../../../../helpers/gasAnalysisReport";
import {
  AnalysisValueColorRanges,
  AnalysisValueRanges,
  HourlyMatrixValueRanges,
} from "../AnalysisConstants";
import { fastSlow, getBiggestQuadrant } from "../ReportUtils";
import { fontWeight } from "html2canvas/dist/types/css/property-descriptors/font-weight";

const CompareIncreasedColor = "#00b800";
const CompareDecreasedColor = "#e72525";
const CompareSameColor = "#157ae2";

function getGoodnessHeight(
  value: number,
  fieldName: "CH4" | "C02" | "Acetate" | "Propionate" | "TotalVFA"
) {
  const maxHeight = 58;
  const min = HourlyMatrixValueRanges[fieldName].min;
  const good = HourlyMatrixValueRanges[fieldName].good;
  const max = HourlyMatrixValueRanges[fieldName].max;
  const maxDiff = max - min;
  const currentDiff = value - min;
  return (currentDiff / maxDiff) * maxHeight;
}

function getGoodnessColor(
  value: number,
  fieldName: "CH4" | "C02" | "Acetate" | "Propionate" | "TotalVFA"
) {
  const min = HourlyMatrixValueRanges[fieldName].min;
  const good = HourlyMatrixValueRanges[fieldName].good;
  const max = HourlyMatrixValueRanges[fieldName].max;

  const maxGoodRange = good + (max - good) / 2.5;
  const minGoodRange = good - (good - min) / 2.5;
  const rangeWidth = maxGoodRange - minGoodRange;
  const ltw = rangeWidth / 2; // Lower Transition width
  const htw = rangeWidth / 2; // higher transition width

  const lowerTransStart = minGoodRange - ltw;
  const upperTransEnd = maxGoodRange + htw;

  let hue = 120; // Green hue
  if (value < good) {
    return `hsl(120deg 80% 55% / ${
      value < min ? 0 : Math.min(10 + (100 * (value - min)) / (good - min), 100)
    }%)`;
  }
  if (value <= lowerTransStart || value >= upperTransEnd) {
    hue = 24; // when worst case, show 80%  of red
    return "hsl(" + hue + ",80%,55%)";
  } else if (value >= minGoodRange && value <= maxGoodRange) {
    // fully green
    return "hsl(" + hue + ",80%,55%)";
  } else if (value > lowerTransStart && value < minGoodRange) {
    const factor = (minGoodRange - value) / ltw;
    hue = Math.round(120 * (1 - factor)); // Interpolate towards green
  } else if (value > maxGoodRange && value < upperTransEnd) {
    const factor = (value - maxGoodRange) / htw / 1.25; // when worst case, show 80%  of red
    hue = Math.round(120 * (1 - factor)); // Interpolate towards red
  }

  return "hsl(" + hue + ",93%,44%)"; // Return HSL color
}

const getMbpOmd = (sampleRecord: ISampleRecordPopulated) => {
  if (!sampleRecord.chemicals?.["MBP"] || !sampleRecord.chemicals?.["OMD"]) {
    return undefined;
  }
  return Number(
    (
      (100 * sampleRecord.chemicals?.["MBP"]) /
      2.5 /
      (sampleRecord.chemicals?.["OMD"] * 4)
    ).toFixed(1)
  );
};

const getCh4Mbp = (sampleRecord: ISampleRecordPopulated) => {
  if (!sampleRecord.chemicals?.["CH4_24"] || !sampleRecord.chemicals?.["MBP"]) {
    return undefined;
  }
  return Number(
    (
      (100 * sampleRecord.chemicals?.["CH4_24"]) /
      sampleRecord.chemicals?.["MBP"]
    ).toFixed(1)
  );
};

const getC02Dmi = (sampleRecord: ISampleRecordPopulated) => {
  if (
    !sampleRecord.chemicals?.["CH4_24"] ||
    !sampleRecord.chemicals?.["C02_24"] ||
    !sampleRecord.chemicals?.["N20"]
  ) {
    return undefined;
  }
  return Number(
    (
      274 * sampleRecord.chemicals?.["CH4_24"] +
      sampleRecord.chemicals?.["C02_24"] +
      sampleRecord.chemicals?.["N20"] * 700
    ).toFixed(1)
  );
};

interface IChartDataItem {
  total: number;
  fast: number;
  slow: number;
  time: number;
}

export default function GasAnalysisGraph1({
  sampleRecord,
  comparedSampleRecord,
  hideHourlyMatrix = false,
  hideKeyValueBlocks = false,
}: {
  comparedSampleRecord?: ISampleRecordPopulated;
  sampleRecord: ISampleRecordPopulated;
  hideHourlyMatrix?: boolean;
  hideKeyValueBlocks?: boolean;
}) {
  const quadrant = getBiggestQuadrant(sampleRecord);
  const comparedQuadrant = comparedSampleRecord
    ? getBiggestQuadrant(comparedSampleRecord)
    : null;
  const chartData: IChartDataItem[] = [];
  const comparedChartData: IChartDataItem[] = [];

  const a = sampleRecord.chemicals?.["asym1st"] || 0;
  const b = sampleRecord.chemicals?.["Fast_HR"] || 0;
  const c = sampleRecord.chemicals?.["LagTime"] || 0;
  const d = sampleRecord.chemicals?.["asym2nd"] || 0;
  const e = sampleRecord.chemicals?.["Slow_HR"] || 0;

  const comparedA = comparedSampleRecord?.chemicals?.["asym1st"] || 0;
  const comparedB = comparedSampleRecord?.chemicals?.["Fast_HR"] || 0;
  const comparedC = comparedSampleRecord?.chemicals?.["LagTime"] || 0;
  const comparedD = comparedSampleRecord?.chemicals?.["asym2nd"] || 0;
  const comparedE = comparedSampleRecord?.chemicals?.["Slow_HR"] || 0;

  let totalAt18 = 0;
  let totalAtLast = 0;
  let fastAtLast = 0;
  let slowAtLast = 0;

  let comparedTotalAt18 = 0;
  let comparedTotalAtLast = 0;
  let comparedFastAtLast = 0;
  let comparedSlowAtLast = 0;

  for (let t = 0; t <= 30; t = t + 0.1) {
    const mins = t * 60;
    const fast = a / (1 + Math.exp(2 + 4 * b * (c - mins)));
    const slow = d / (1 + Math.exp(2 + 4 * e * (c - mins)));
    const total = fast + slow;
    const chartDataItem: IChartDataItem = {
      total,
      fast: fast,
      slow: slow,
      time: t,
    };
    chartData.push(chartDataItem);
    if (t > 18 && t < 18.1) {
      totalAt18 = total;
    }
    if (t > 29.9) {
      totalAtLast = total;
      fastAtLast = fast;
      slowAtLast = slow;
    }
  }

  for (let t = 0; t <= 30; t = t + 0.1) {
    const mins = t * 60;
    const fast =
      comparedA / (1 + Math.exp(2 + 4 * comparedB * (comparedC - mins)));
    const slow =
      comparedD / (1 + Math.exp(2 + 4 * comparedE * (comparedC - mins)));
    const total = fast + slow;
    const comparedChartDataItem: IChartDataItem = {
      total,
      fast: fast,
      slow: slow,
      time: t,
    };
    comparedChartData.push(comparedChartDataItem);
    if (t > 18 && t < 18.1) {
      comparedTotalAt18 = total;
    }
    if (t > 29.9) {
      comparedTotalAtLast = total;
      comparedFastAtLast = fast;
      comparedSlowAtLast = slow;
    }
  }

  const problemMatrixValues: {
    problemValues: { valueLabel: string; value: number }[];
    hour: number;
  }[] = [];

  for (let hr = 3; hr < 25; hr = hr * 2) {
    const problemValues: { valueLabel: string; value: number }[] = [];
    const ch4Value = sampleRecord.chemicals?.[`CH4_${hr}`];
    const ch4WarningValue =
      HourlyMatrixValueRanges.CH4.max -
      (HourlyMatrixValueRanges.CH4.max - HourlyMatrixValueRanges.CH4.min) / 3;
    if (ch4Value && ch4Value > ch4WarningValue) {
      problemValues.push({
        valueLabel: "CH4",
        value: ch4Value,
      });
    }
    const c02Value = sampleRecord.chemicals?.[`C02_${hr}`];
    const c02WarningValue =
      HourlyMatrixValueRanges.C02.max -
      (HourlyMatrixValueRanges.C02.max - HourlyMatrixValueRanges.C02.min) / 3;
    if (c02Value && c02Value >= c02WarningValue) {
      problemValues.push({
        valueLabel: "C02",
        value: c02Value,
      });
    }
    const acetateValue = getAcetate(
      Number(sampleRecord.chemicals?.[`ORP_${hr}`])
    );
    const acetateWarningValue =
      HourlyMatrixValueRanges.Acetate.max -
      (HourlyMatrixValueRanges.Acetate.max -
        HourlyMatrixValueRanges.Acetate.min) /
        3;
    if (acetateValue && acetateValue >= acetateWarningValue) {
      problemValues.push({
        valueLabel: "Acetate",
        value: acetateValue,
      });
    }

    const propionateValue = getPropionate(
      Number(sampleRecord.chemicals?.[`ORP_${hr}`])
    );
    const propionateWarningValue =
      HourlyMatrixValueRanges.Propionate.max -
      (HourlyMatrixValueRanges.Propionate.max -
        HourlyMatrixValueRanges.Propionate.min) /
        3;
    if (propionateValue && propionateValue >= propionateWarningValue) {
      problemValues.push({
        valueLabel: "Propionate",
        value: propionateValue,
      });
    }
    if (problemValues.length) {
      problemMatrixValues.push({
        problemValues,
        hour: hr,
      });
    }

    const tvfaValue = getTotalVFA(
      Number(sampleRecord.chemicals?.[`ORP_${hr}`])
    );
    const tvfaWarningValue =
      HourlyMatrixValueRanges.TotalVFA.max -
      (HourlyMatrixValueRanges.TotalVFA.max -
        HourlyMatrixValueRanges.TotalVFA.min) /
        3;
    if (tvfaValue && tvfaValue >= tvfaWarningValue) {
      problemValues.push({
        valueLabel: "TotalVFA",
        value: tvfaValue,
      });
    }

    if (problemValues.length) {
      problemMatrixValues.push({
        problemValues,
        hour: hr,
      });
    }
  }

  const percentageAt18 =
    Number(((totalAt18 / totalAtLast) * 100).toFixed(1)) || 0;
  const comparedPercentageAt18 =
    Number(((comparedTotalAt18 / comparedTotalAtLast) * 100).toFixed(1)) || 0;
  const [chartRefreshKey, setChartRefreshKey] = React.useState(0);
  const [chartOptions, setChartOptions] =
    React.useState<ApexCharts.ApexOptions>({
      title: {
        text: ``,
        align: "left",
      },
      chart: {
        id: "fed",
        group: "1",
        type: "line",
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false,
        },
      },
      // stroke: {
      //   width: 3, // Adjust the width of the line here
      //   curve: "smooth",
      // },
      stroke: {
        width: [3, 3, 3, 2, 2, 2], // Adjust widths for each series
        curve: "smooth",
        dashArray: [0, 0, 0, 5, 5, 5], // Add dash for compared series
      },
      colors: ["#010101", "#e9b008", "#00b300", "#000", "#000", "#000"],
      series: [
        {
          name: "Total",
          data: chartData.map((r) => Number(r.total?.toFixed(1)) || null),
          color: "#010101",
        },
        {
          name: "Fast",
          data: chartData.map((r) => Number(r.fast?.toFixed(1)) || null),
          color: "#e9b008",
        },
        {
          name: "Slow",
          data: chartData.map((r) => Number(r.slow?.toFixed(1)) || null),
          color: "#00b300",
        },
        ...(comparedSampleRecord
          ? [
              {
                name: "Compared Total",
                data: comparedChartData.map(
                  (r) => Number(r.total?.toFixed(1)) || null
                ),
                color: "#010101",
              } as any,
              {
                name: "Compared Fast",
                data: comparedChartData.map(
                  (r) => Number(r.fast?.toFixed(1)) || null
                ),
                color: "#e9b008",
              },
              {
                name: "Compared Slow",
                data: comparedChartData.map(
                  (r) => Number(r.slow?.toFixed(1)) || null
                ),
                color: "#00b300",
              },
            ]
          : []),
      ],
      xaxis: {
        labels: {
          formatter: (value, timestamp, opts) => {
            return `${Number(value).toFixed(0)} hr`;
          },
        },
        categories: chartData.map((r) => r.time || 0),
        tickAmount: 10,
        title: {},
      },
      yaxis: {
        title: {
          text: "MI Gas Produced per 400 mg Sample",
          style: { fontSize: "13px", fontWeight: 400 },
        },
      },

      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      annotations: {
        xaxis: [
          {
            x: `18 hr`,
            borderColor: "#000",
            fillColor: "#ddd",
            opacity: 0.2,
            label: {
              offsetY: -8,
              orientation: "horizontal",
              borderColor: "#ddd",
              style: {
                fontSize: "12px",
                color: "#333",
                background: "#ddd",
              },
              text: `${percentageAt18}% at 18 hr`,
            },
          },
          ...(comparedSampleRecord
            ? [
                {
                  x: `18 hr`,
                  borderColor: "#fff",
                  fillColor: "#ddd",
                  opacity: 0.2,
                  label: {
                    offsetY: -8,
                    offsetX: 70,
                    orientation: "horizontal",
                    borderColor: "#fff",
                    style: {
                      fontWeight: "600",
                      fontSize: "12px",
                      color:
                        (percentageAt18 || 0) - (comparedPercentageAt18 || 0) >
                        0
                          ? CompareIncreasedColor
                          : (percentageAt18 || 0) -
                              (comparedPercentageAt18 || 0) <
                            0
                          ? CompareDecreasedColor
                          : CompareSameColor,
                      background: "#fff",
                    },
                    text:
                      (percentageAt18 || 0) == (comparedPercentageAt18 || 0)
                        ? "Same"
                        : `${
                            comparedPercentageAt18 < percentageAt18 ? "+" : ""
                          }${(
                            (percentageAt18 || 0) -
                            (comparedPercentageAt18 || 0)
                          ).toFixed(1)}%`,
                  },
                },
              ]
            : []),
          // ...(problemMatrixValues
          //   .map((problemValues) => {
          //     return problemValues.problemValues.map((probValue, i) => ({
          //       x: `${problemValues.hour} hr`,
          //       borderColor: "#bbb",
          //       fillColor: "#ddd",
          //       opacity: 0.2,
          //       label: {
          //         offsetY: 10 + i * 20,
          //         orientation: "horizontal",
          //         borderColor: getGoodnessColor(
          //           probValue.value,
          //           probValue.valueLabel as any
          //         ),
          //         style: {
          //           fontWeight: "600",
          //           fontSize: "12px",
          //           color: "#333",
          //           background: getGoodnessColor(
          //             probValue.value,
          //             probValue.valueLabel as any
          //           ),
          //         },
          //         text: `${probValue.valueLabel}: ${Number(
          //           probValue.value.toFixed(2)
          //         )}`,
          //       },
          //     }));
          //   })
          //   .flat(Infinity) as any),
        ],
        points: [
          {
            x: `28 hr`,
            y: totalAtLast,
            marker: {
              size: 0,
              fillColor: "#fff",
              strokeColor: "red",
              radius: 2,
              cssClass: "apexcharts-custom-class",
            },
            label: {
              borderColor: "#010101",
              offsetY: 25,
              style: {
                color: "#fff",
                background: "#010101",
              },
              text: `Total: ${(totalAtLast || 0).toFixed(1)} ml`,
            },
          },
          {
            x: `28 hr`,
            y: slowAtLast,
            marker: {
              size: 0,
              fillColor: "#fff",
              strokeColor: "red",
              radius: 2,
              cssClass: "apexcharts-custom-class",
            },
            label: {
              borderColor: "#00b300",
              offsetY: 25,
              style: {
                color: "#fff",
                background: "#00b300",
              },
              text: `Slow: ${(slowAtLast || 0).toFixed(1)} ml`,
            },
          },
          {
            x: `28 hr`,
            y: fastAtLast,
            marker: {
              size: 0,
              fillColor: "#fff",
              strokeColor: "red",
              radius: 2,
              cssClass: "apexcharts-custom-class",
            },
            label: {
              borderColor: "#e9b008",
              offsetY: 25,
              style: {
                color: "#fff",
                background: "#e9b008",
              },
              text: `Fast: ${(fastAtLast || 0).toFixed(1)} ml`,
            },
          },
          ...(comparedSampleRecord
            ? [
                {
                  x: `28 hr`,
                  y: totalAtLast,
                  marker: {
                    size: 0,
                    fillColor: "#fff",
                    strokeColor: "red",
                    radius: 2,
                    cssClass: "apexcharts-custom-class",
                  },
                  label: {
                    borderColor: "#fff",
                    offsetY: 42,
                    style: {
                      fontWeight: "600",
                      color:
                        (totalAtLast || 0) - (comparedTotalAtLast || 0) > 0
                          ? CompareIncreasedColor
                          : (totalAtLast || 0) - (comparedTotalAtLast || 0) < 0
                          ? CompareDecreasedColor
                          : CompareSameColor,
                      background: "#fff",
                    },
                    text:
                      (totalAtLast || 0) == (comparedTotalAtLast || 0)
                        ? "Same"
                        : `${comparedTotalAtLast < totalAtLast ? "+" : ""}${(
                            (totalAtLast || 0) - (comparedTotalAtLast || 0)
                          ).toFixed(1)} ml`,
                  },
                },
                {
                  x: `28 hr`,
                  y: slowAtLast,
                  marker: {
                    size: 0,
                    fillColor: "#fff",
                    strokeColor: "red",
                    radius: 2,
                    cssClass: "apexcharts-custom-class",
                  },
                  label: {
                    borderColor: "#fff",
                    offsetY: 42,
                    style: {
                      fontWeight: "600",
                      color:
                        (slowAtLast || 0) - (comparedSlowAtLast || 0) > 0
                          ? CompareIncreasedColor
                          : (slowAtLast || 0) - (comparedSlowAtLast || 0) < 0
                          ? CompareDecreasedColor
                          : CompareSameColor,
                      background: "#fff",
                    },
                    text:
                      (slowAtLast || 0) == (comparedSlowAtLast || 0)
                        ? "Same"
                        : `${comparedSlowAtLast < slowAtLast ? "+" : ""}${(
                            (slowAtLast || 0) - (comparedSlowAtLast || 0)
                          ).toFixed(1)} ml`,
                  },
                },
                {
                  x: `28 hr`,
                  y: fastAtLast,
                  marker: {
                    size: 0,
                    fillColor: "#fff",
                    strokeColor: "red",
                    radius: 2,
                    cssClass: "apexcharts-custom-class",
                  },
                  label: {
                    borderColor: "#fff",
                    offsetY: 42,
                    style: {
                      fontWeight: "600",
                      color:
                        (fastAtLast || 0) - (comparedFastAtLast || 0) > 0
                          ? CompareIncreasedColor
                          : (fastAtLast || 0) - (comparedFastAtLast || 0) < 0
                          ? CompareDecreasedColor
                          : CompareSameColor,
                      background: "#fff",
                    },
                    text:
                      (fastAtLast || 0) == (comparedFastAtLast || 0)
                        ? "Same"
                        : `${comparedFastAtLast < fastAtLast ? "+" : ""}${(
                            (fastAtLast || 0) - (comparedFastAtLast || 0)
                          ).toFixed(1)} ml`,
                  },
                },
              ]
            : []),
        ],
      },
    });
  return (
    <Box sx={{ position: "relative", marginTop: "-15px" }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={hideHourlyMatrix ? 12 : 7}>
          <div
            style={{
              position: "relative",
              marginBottom: comparedSampleRecord ? "-10px" : "-20px",
            }}
          >
            <ReactApexChart
              key={`chart-${chartRefreshKey}`}
              options={chartOptions}
              series={chartOptions.series}
              type="line"
              height={hideHourlyMatrix ? 360 : 300}
            />
            {quadrant != null && !isNaN(quadrant) ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "absolute",
                  bottom: "84px",
                  left: "50%",
                  transform: "translate(-39%, 0px)",
                  background: "#b5dcff",
                  padding: "2px 5px",
                  borderRadius: "3px",
                  fontSize: "13px",
                }}
              >
                <div
                  style={{
                    padding: "0px 4px",
                    borderRadius: "3px",
                    display: "flex",
                    fontWeight: "600",
                  }}
                >
                  {`Q${quadrant + 1}`}
                  {quadrant == 3 ? (
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          fontSize: "13px",
                          marginLeft: "6px",
                          marginTop: "-2px",
                        }}
                      >
                        y
                      </div>
                      <div
                        style={{
                          fontStyle: "italic",
                          fontFamily: "auto",
                          fontSize: "13px",
                          marginLeft: "2px",
                          marginTop: "-2px",
                        }}
                      >
                        f
                      </div>
                    </div>
                  ) : null}
                </div>
                <div style={{ whiteSpace: "nowrap" }}>
                  {`: ${fastSlow[Math.floor(quadrant / 2)]} "fast pool" and ${
                    fastSlow[quadrant % 2]
                  } "slow pool"`}
                </div>
              </div>
            ) : null}
            {comparedSampleRecord ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  bottom: "20px",
                  padding: "2px 5px",
                  borderRadius: "3px",
                  fontSize: "13px",
                }}
              >
                <Stack spacing={"3px"} mr={1}>
                  <div
                    style={{
                      width: "30px",
                      height: "0px",
                      borderTop: "dashed 3px #010101",
                    }}
                  />
                  <div
                    style={{
                      width: "30px",
                      height: "0px",
                      borderTop: "dashed 3px #e9b008",
                    }}
                  />
                  <div
                    style={{
                      width: "30px",
                      height: "0px",
                      borderTop: "dashed 3px #00b300",
                    }}
                  />
                </Stack>
                {comparedSampleRecord.sampleNumber}

                <Stack spacing={"3px"} ml={4} mr={1}>
                  <div
                    style={{
                      width: "30px",
                      height: "0px",
                      borderTop: "solid 3px #010101",
                    }}
                  />
                  <div
                    style={{
                      width: "30px",
                      height: "0px",
                      borderTop: "solid 3px #e9b008",
                    }}
                  />
                  <div
                    style={{
                      width: "30px",
                      height: "0px",
                      borderTop: "solid 3px #00b300",
                    }}
                  />
                </Stack>
                {sampleRecord.sampleNumber}
              </div>
            ) : null}
          </div>
          {!hideKeyValueBlocks ? (
            <Stack
              direction="row"
              spacing={1}
              sx={{
                display: "flex",
                mb: "10px",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  border: "solid 3px #bfbfbf",
                  background: "white",
                  fontSize: "14px",
                  borderRadius: "3px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    background: "#ddd",
                    padding: "2px 5px",
                    paddingTop: "1px",
                    textAlign: "center",
                  }}
                >
                  MBP
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "38px",
                      fontWeight: 600,
                      marginRight: "4px",
                      color: sampleRecord.chemicals?.["MBP"]
                        ? AnalysisValueColorRanges["MBP"].find((range) => {
                            const fromValue =
                              AnalysisValueRanges["MBP"].min +
                              ((AnalysisValueRanges["MBP"].max -
                                AnalysisValueRanges["MBP"].min) *
                                range.from) /
                                100;
                            const toValue =
                              AnalysisValueRanges["MBP"].min +
                              ((AnalysisValueRanges["MBP"].max -
                                AnalysisValueRanges["MBP"].min) *
                                range.to) /
                                100;

                            return (
                              fromValue <
                                (sampleRecord.chemicals?.["MBP"] as any) &&
                              toValue > (sampleRecord.chemicals?.["MBP"] as any)
                            );
                          })?.color || "#333"
                        : "#333",
                    }}
                  >
                    {Number(sampleRecord.chemicals?.["MBP"]?.toFixed(1))}
                  </div>
                  <div>
                    <div style={{ fontSize: "17px", marginBottom: "3px" }}>
                      (mg/g)
                    </div>
                    <div style={{ fontSize: "11px", opacity: 0.8 }}>Rumen</div>
                  </div>
                </Box>
                {comparedSampleRecord &&
                Number(comparedSampleRecord.chemicals?.["MBP"]) ? (
                  <div
                    style={{
                      fontSize: "14px",
                      textAlign: "center",
                      marginTop: "3px",
                      marginBottom: "3px",
                      color:
                        Number(sampleRecord.chemicals?.["MBP"]?.toFixed(1)) -
                          Number(
                            comparedSampleRecord.chemicals?.["MBP"]?.toFixed(1)
                          ) >
                        0
                          ? CompareIncreasedColor
                          : Number(
                              sampleRecord.chemicals?.["MBP"]?.toFixed(1)
                            ) -
                              Number(
                                comparedSampleRecord.chemicals?.[
                                  "MBP"
                                ]?.toFixed(1)
                              ) <
                            0
                          ? CompareDecreasedColor
                          : CompareSameColor,
                      fontWeight: 600,
                    }}
                  >
                    {`${
                      Number(sampleRecord.chemicals?.["MBP"]?.toFixed(1)) -
                        Number(
                          comparedSampleRecord.chemicals?.["MBP"]?.toFixed(1)
                        ) >
                      0
                        ? "+"
                        : ""
                    }${(
                      Number(sampleRecord.chemicals?.["MBP"]?.toFixed(1)) -
                      Number(
                        comparedSampleRecord.chemicals?.["MBP"]?.toFixed(1)
                      )
                    ).toFixed(2)}`}
                  </div>
                ) : null}
              </Box>
              <Box
                sx={{
                  flex: 2,
                  border: "solid 3px #bfbfbf",
                  background: "white",
                  fontSize: "14px",
                  borderRadius: "3px",
                  marginTop: "-3px",
                }}
              >
                <Box
                  sx={{
                    background: "#ddd",
                    padding: "2px 5px",
                    textAlign: "center",
                    paddingTop: "1px",
                  }}
                >
                  24 Hr Efficiency
                </Box>
                <Stack
                  sx={{ display: "flex", p: "3px", pt: "4px" }}
                  direction="row"
                  spacing={"3px"}
                >
                  <Box sx={{ flex: 1, border: "solid 2px #e2e2e2" }}>
                    <div
                      style={{
                        background: "#e2e2e2",
                        padding: "2px 3px",
                        whiteSpace: "nowrap",
                        paddingTop: "0px",
                        fontSize: "13px",
                        textAlign: "center",
                      }}
                    >
                      MBP g/g OMD
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontWeight: 600,
                        color: "#333",
                        padding: "3px 0px",
                        fontSize: "18px",
                      }}
                    >
                      {getMbpOmd(sampleRecord)
                        ? `${getMbpOmd(sampleRecord)} %`
                        : ""}
                    </div>
                    {comparedSampleRecord &&
                    getMbpOmd(sampleRecord) &&
                    getMbpOmd(comparedSampleRecord) ? (
                      <div
                        style={{
                          fontWeight: "600",
                          fontSize: "14px",
                          textAlign: "center",
                          marginTop: "3px",
                          marginBottom: "3px",
                          color:
                            (getMbpOmd(sampleRecord) || 0) -
                              (getMbpOmd(comparedSampleRecord) || 0) >
                            0
                              ? CompareIncreasedColor
                              : (getMbpOmd(sampleRecord) || 0) -
                                  (getMbpOmd(comparedSampleRecord) || 0) <
                                0
                              ? CompareDecreasedColor
                              : CompareSameColor,
                        }}
                      >
                        {`${
                          (getMbpOmd(sampleRecord) || 0) -
                            (getMbpOmd(comparedSampleRecord) || 0) >
                          0
                            ? "+"
                            : ""
                        }${(
                          (getMbpOmd(sampleRecord) || 0) -
                          (getMbpOmd(comparedSampleRecord) || 0)
                        ).toFixed(1)}`}
                      </div>
                    ) : null}
                  </Box>
                  <Box sx={{ flex: 1, border: "solid 2px #e2e2e2" }}>
                    <div
                      style={{
                        background: "#e2e2e2",
                        padding: "2px 3px",
                        whiteSpace: "nowrap",
                        paddingTop: "0px",
                        fontSize: "13px",
                        textAlign: "center",
                      }}
                    >
                      CH4 g/g MBP
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontWeight: 600,
                        color: "#333",
                        padding: "3px 0px",
                        fontSize: "18px",
                      }}
                    >
                      {getCh4Mbp(sampleRecord)
                        ? `${getCh4Mbp(sampleRecord)?.toFixed(1)} %`
                        : ""}
                    </div>
                    {comparedSampleRecord &&
                    getCh4Mbp(sampleRecord) &&
                    getCh4Mbp(comparedSampleRecord) ? (
                      <div
                        style={{
                          fontWeight: "600",
                          fontSize: "14px",
                          textAlign: "center",
                          marginTop: "3px",
                          marginBottom: "3px",
                          color:
                            (getCh4Mbp(sampleRecord) || 0) -
                              (getCh4Mbp(comparedSampleRecord) || 0) >
                            0
                              ? CompareIncreasedColor
                              : (getCh4Mbp(sampleRecord) || 0) -
                                  (getCh4Mbp(comparedSampleRecord) || 0) <
                                0
                              ? CompareDecreasedColor
                              : CompareSameColor,
                        }}
                      >
                        {`${
                          (getCh4Mbp(sampleRecord) || 0) -
                            (getCh4Mbp(comparedSampleRecord) || 0) >
                          0
                            ? "+"
                            : ""
                        }${(
                          (getCh4Mbp(sampleRecord) || 0) -
                          (getCh4Mbp(comparedSampleRecord) || 0)
                        ).toFixed(1)}`}
                      </div>
                    ) : null}
                  </Box>
                  <Box sx={{ flex: 1, border: "solid 2px #e2e2e2" }}>
                    <div
                      style={{
                        background: "#e2e2e2",
                        padding: "2px 3px",
                        whiteSpace: "nowrap",
                        paddingTop: "0px",
                        fontSize: "13px",
                        textAlign: "center",
                      }}
                    >
                      C02e g/kg DMI
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontWeight: 600,
                        color: "#333",
                        padding: "3px 0px",
                        fontSize: "18px",
                      }}
                    >
                      {getC02Dmi(sampleRecord)
                        ? `${getC02Dmi(sampleRecord)}`
                        : ""}
                    </div>
                    {comparedSampleRecord &&
                    getC02Dmi(sampleRecord) &&
                    getC02Dmi(comparedSampleRecord) ? (
                      <div
                        style={{
                          fontWeight: "600",
                          fontSize: "14px",
                          textAlign: "center",
                          marginTop: "3px",
                          marginBottom: "3px",
                          color:
                            (getC02Dmi(sampleRecord) || 0) -
                              (getC02Dmi(comparedSampleRecord) || 0) >
                            0
                              ? CompareIncreasedColor
                              : (getC02Dmi(sampleRecord) || 0) -
                                  (getC02Dmi(comparedSampleRecord) || 0) <
                                0
                              ? CompareDecreasedColor
                              : CompareSameColor,
                        }}
                      >
                        {`${
                          (getC02Dmi(sampleRecord) || 0) -
                            (getC02Dmi(comparedSampleRecord) || 0) >
                          0
                            ? "+"
                            : ""
                        }${(
                          (getC02Dmi(sampleRecord) || 0) -
                          (getC02Dmi(comparedSampleRecord) || 0)
                        ).toFixed(1)}`}
                      </div>
                    ) : null}
                  </Box>
                </Stack>
                {/* <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "31px",
                      fontWeight: 600,
                      marginRight: "8px",
                      color: "#00b300",
                    }}
                  >
                    {12}
                  </div>
                  <div style={{ fontSize: "20px" }}>%</div>
                </Box> */}
              </Box>
            </Stack>
          ) : null}
        </Grid>
        {!hideHourlyMatrix ? (
          <Grid item xs={12} md={5}>
            <Box
              sx={{
                paddingBottom: "0px",
                borderRadius: "5px",
                mt: 3,
                "& .MuiTableCell-root": {
                  padding: "3px 0px",
                  minHeight: "40px",
                  border: "none",
                  verticalAlign: "bottom !important",
                  "&.table-row-label": {
                    padding: "3px 6px",
                    paddingBottom: "4px",
                    paddingRight: "12px",
                    justifyContent: "right",
                    textAlign: "right",
                    "& .table-row-label-title": {
                      fontWeight: "500",
                    },
                    "& .table-row-label-unit": {
                      fontSize: "13px",
                      color: "#888",
                    },
                  },
                  "& .cell-value": {
                    padding: "0px 8px",
                    paddingTop: "3px",
                    textAlign: "center",
                  },
                  "& .cell-pillar": {
                    margin: "0px 2px",
                    marginTop: "4px",
                  },
                },
                "& thead .MuiTableCell-root": {
                  borderBottom: "solid 1px #c1c1c1",
                },
              }}
            >
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell className="table-row-label"></TableCell>
                    <TableCell>3 hr</TableCell>
                    <TableCell>6 hr</TableCell>
                    <TableCell>12 hr</TableCell>
                    <TableCell>24 hr</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow style={{ borderBottom: "solid 1px #ddd" }}>
                    <TableCell className="table-row-label">
                      <div className="table-row-label-title">CH4</div>
                      <div className="table-row-label-unit">(g/kg DMI)</div>
                    </TableCell>
                    {[3, 6, 12, 24].map((hr) => {
                      return (
                        <TableCell key={`ch4-${hr}`}>
                          <div
                            className="cell-pillar"
                            style={{
                              position: "relative",
                              height: `${getGoodnessHeight(
                                Number(sampleRecord.chemicals?.[`CH4_${hr}`]),
                                "CH4"
                              )}px`,
                              backgroundColor: sampleRecord.chemicals?.[
                                `CH4_${hr}`
                              ]
                                ? getGoodnessColor(
                                    Number(
                                      sampleRecord.chemicals?.[`CH4_${hr}`]
                                    ),
                                    "CH4"
                                  )
                                : undefined,
                            }}
                          >
                            {comparedSampleRecord &&
                            comparedSampleRecord.chemicals?.[`CH4_${hr}`] ? (
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: "0px",
                                  width: "100%",
                                  height: `${getGoodnessHeight(
                                    Number(
                                      comparedSampleRecord.chemicals?.[
                                        `CH4_${hr}`
                                      ]
                                    ),
                                    "CH4"
                                  )}px`,
                                  borderTop: !comparedSampleRecord.chemicals?.[
                                    `CH4_${hr}`
                                  ]
                                    ? ""
                                    : `dashed 2px ${CompareDecreasedColor}`,
                                }}
                              />
                            ) : null}
                          </div>
                          <div className="cell-value">
                            {sampleRecord.chemicals?.[`CH4_${hr}`]
                              ? Number(
                                  sampleRecord.chemicals?.[`CH4_${hr}`]
                                ).toFixed(1)
                              : ""}
                          </div>
                          {[3, 6, 12, 24].some(
                            (eachHr) =>
                              sampleRecord.chemicals?.[`CH4_${eachHr}`] &&
                              comparedSampleRecord?.chemicals?.[`CH4_${eachHr}`]
                          ) ? (
                            <div
                              style={{
                                color:
                                  (Number(
                                    sampleRecord.chemicals?.[`CH4_${hr}`]
                                  ) || 0) -
                                    (Number(
                                      comparedSampleRecord?.chemicals?.[
                                        `CH4_${hr}`
                                      ]
                                    ) || 0) >=
                                  0
                                    ? CompareIncreasedColor
                                    : CompareDecreasedColor,
                                fontWeight: "600",
                                fontSize: "14px",
                                textAlign: "center",
                                padding: "0px 8px",
                              }}
                            >
                              {sampleRecord.chemicals?.[`CH4_${hr}`] &&
                              comparedSampleRecord?.chemicals?.[`CH4_${hr}`]
                                ? `${
                                    (Number(
                                      sampleRecord.chemicals?.[`CH4_${hr}`]
                                    ) || 0) -
                                      (Number(
                                        comparedSampleRecord.chemicals?.[
                                          `CH4_${hr}`
                                        ]
                                      ) || 0) >
                                    0
                                      ? "+"
                                      : ""
                                  }${(
                                    (Number(
                                      sampleRecord.chemicals?.[`CH4_${hr}`]
                                    ) || 0) -
                                    (Number(
                                      comparedSampleRecord.chemicals?.[
                                        `CH4_${hr}`
                                      ]
                                    ) || 0)
                                  ).toFixed(1)}`
                                : ""}
                            </div>
                          ) : null}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  <TableRow style={{ borderBottom: "solid 1px #ddd" }}>
                    <TableCell className="table-row-label">
                      <div className="table-row-label-title">C02</div>
                      <div className="table-row-label-unit">(g/kg DMI)</div>
                    </TableCell>
                    {[3, 6, 12, 24].map((hr) => (
                      <TableCell key={`c02-${hr}`}>
                        <div
                          className="cell-pillar"
                          style={{
                            position: "relative",
                            height: `${getGoodnessHeight(
                              Number(sampleRecord.chemicals?.[`C02_${hr}`]),
                              "C02"
                            )}px`,
                            backgroundColor: sampleRecord.chemicals?.[
                              `C02_${hr}`
                            ]
                              ? getGoodnessColor(
                                  Number(sampleRecord.chemicals?.[`C02_${hr}`]),
                                  "C02"
                                )
                              : undefined,
                          }}
                        >
                          {comparedSampleRecord &&
                          comparedSampleRecord.chemicals?.[`C02_${hr}`] ? (
                            <div
                              style={{
                                position: "absolute",
                                bottom: "0px",
                                width: "100%",
                                height: `${getGoodnessHeight(
                                  Number(
                                    comparedSampleRecord.chemicals?.[
                                      `C02_${hr}`
                                    ]
                                  ),
                                  "C02"
                                )}px`,
                                borderTop: !comparedSampleRecord.chemicals?.[
                                  `C02_${hr}`
                                ]
                                  ? ""
                                  : `dashed 2px ${CompareDecreasedColor}`,
                              }}
                            />
                          ) : null}
                        </div>
                        <div className="cell-value">
                          {sampleRecord.chemicals?.[`C02_${hr}`]
                            ? Number(
                                sampleRecord.chemicals?.[`C02_${hr}`]
                              ).toFixed(2)
                            : ""}
                        </div>
                        {[3, 6, 12, 24].some(
                          (eachHr) =>
                            sampleRecord.chemicals?.[`C02_${eachHr}`] &&
                            comparedSampleRecord?.chemicals?.[`C02_${eachHr}`]
                        ) ? (
                          <div
                            style={{
                              color:
                                (Number(
                                  sampleRecord.chemicals?.[`C02_${hr}`]
                                ) || 0) -
                                  (Number(
                                    comparedSampleRecord?.chemicals?.[
                                      `C02_${hr}`
                                    ]
                                  ) || 0) >=
                                0
                                  ? CompareIncreasedColor
                                  : CompareDecreasedColor,
                              fontWeight: "600",
                              fontSize: "14px",
                              textAlign: "center",
                              padding: "0px 8px",
                            }}
                          >
                            {sampleRecord.chemicals?.[`C02_${hr}`] &&
                            comparedSampleRecord?.chemicals?.[`C02_${hr}`]
                              ? `${
                                  (Number(
                                    sampleRecord.chemicals?.[`C02_${hr}`]
                                  ) || 0) -
                                    (Number(
                                      comparedSampleRecord.chemicals?.[
                                        `C02_${hr}`
                                      ]
                                    ) || 0) >
                                  0
                                    ? "+"
                                    : ""
                                }${(
                                  (Number(
                                    sampleRecord.chemicals?.[`C02_${hr}`]
                                  ) || 0) -
                                  (Number(
                                    comparedSampleRecord.chemicals?.[
                                      `C02_${hr}`
                                    ]
                                  ) || 0)
                                ).toFixed(2)}`
                              : ""}
                          </div>
                        ) : null}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow style={{ borderBottom: "solid 1px #ddd" }}>
                    <TableCell className="table-row-label">
                      <div className="table-row-label-title">Acetate</div>
                      <div className="table-row-label-unit">(%tVFA)</div>
                    </TableCell>
                    {[3, 6, 12, 24].map((hr) => (
                      <TableCell key={`acetate-${hr}`}>
                        <div
                          className="cell-pillar"
                          style={{
                            position: "relative",
                            height: `${getGoodnessHeight(
                              getAcetate(
                                Number(sampleRecord.chemicals?.[`ORP_${hr}`])
                              ),
                              "Acetate"
                            )}px`,
                            backgroundColor: sampleRecord.chemicals?.[
                              `ORP_${hr}`
                            ]
                              ? getGoodnessColor(
                                  getAcetate(
                                    Number(
                                      sampleRecord.chemicals?.[`ORP_${hr}`]
                                    )
                                  ),
                                  "Acetate"
                                )
                              : undefined,
                          }}
                        >
                          {comparedSampleRecord &&
                          comparedSampleRecord.chemicals?.[`ORP_${hr}`] ? (
                            <div
                              style={{
                                position: "absolute",
                                bottom: "0px",
                                width: "100%",
                                height: `${getGoodnessHeight(
                                  getAcetate(
                                    Number(
                                      comparedSampleRecord.chemicals?.[
                                        `ORP_${hr}`
                                      ]
                                    )
                                  ),
                                  "Acetate"
                                )}px`,
                                borderTop: !comparedSampleRecord.chemicals?.[
                                  `ORP_${hr}`
                                ]
                                  ? ""
                                  : `dashed 2px ${CompareDecreasedColor}`,
                              }}
                            />
                          ) : null}
                        </div>
                        <div className="cell-value">
                          {sampleRecord.chemicals?.[`ORP_${hr}`]
                            ? getAcetate(
                                Number(sampleRecord.chemicals?.[`ORP_${hr}`])
                              )
                            : ""}
                        </div>
                        {[3, 6, 12, 24].some(
                          (eachHr) =>
                            sampleRecord.chemicals?.[`ORP_${eachHr}`] &&
                            comparedSampleRecord?.chemicals?.[`ORP_${eachHr}`]
                        ) ? (
                          <div
                            style={{
                              color:
                                (getAcetate(
                                  Number(sampleRecord.chemicals?.[`ORP_${hr}`])
                                ) || 0) -
                                  (getAcetate(
                                    Number(
                                      comparedSampleRecord?.chemicals?.[
                                        `ORP_${hr}`
                                      ]
                                    )
                                  ) || 0) >=
                                0
                                  ? CompareIncreasedColor
                                  : CompareDecreasedColor,
                              fontWeight: "600",
                              fontSize: "14px",
                              textAlign: "center",
                              padding: "0px 8px",
                            }}
                          >
                            {sampleRecord.chemicals?.[`ORP_${hr}`] &&
                            comparedSampleRecord?.chemicals?.[`ORP_${hr}`]
                              ? `${
                                  getAcetate(
                                    Number(
                                      sampleRecord.chemicals?.[`ORP_${hr}`]
                                    ) || 0
                                  ) -
                                    getAcetate(
                                      Number(
                                        comparedSampleRecord.chemicals?.[
                                          `ORP_${hr}`
                                        ]
                                      ) || 0
                                    ) >
                                  0
                                    ? "+"
                                    : ""
                                }${Number(
                                  (
                                    getAcetate(
                                      Number(
                                        sampleRecord.chemicals?.[`ORP_${hr}`]
                                      ) || 0
                                    ) -
                                    getAcetate(
                                      Number(
                                        comparedSampleRecord.chemicals?.[
                                          `ORP_${hr}`
                                        ]
                                      ) || 0
                                    )
                                  ).toFixed(2)
                                )}`
                              : ""}
                          </div>
                        ) : null}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow style={{ borderBottom: "solid 1px #ddd" }}>
                    <TableCell className="table-row-label">
                      <div className="table-row-label-title">Propionate</div>
                      <div className="table-row-label-unit">(%tVFA)</div>
                    </TableCell>
                    {[3, 6, 12, 24].map((hr) => (
                      <TableCell key={`propionate-${hr}`}>
                        <div
                          className="cell-pillar"
                          style={{
                            position: "relative",
                            height: `${getGoodnessHeight(
                              getPropionate(
                                Number(sampleRecord.chemicals?.[`ORP_${hr}`])
                              ),
                              "Propionate"
                            )}px`,
                            backgroundColor: sampleRecord.chemicals?.[
                              `ORP_${hr}`
                            ]
                              ? getGoodnessColor(
                                  getPropionate(
                                    Number(
                                      sampleRecord.chemicals?.[`ORP_${hr}`]
                                    )
                                  ),
                                  "Propionate"
                                )
                              : undefined,
                          }}
                        >
                          {comparedSampleRecord &&
                          comparedSampleRecord.chemicals?.[`ORP_${hr}`] ? (
                            <div
                              style={{
                                position: "absolute",
                                bottom: "0px",
                                width: "100%",
                                height: `${getGoodnessHeight(
                                  getPropionate(
                                    Number(
                                      comparedSampleRecord.chemicals?.[
                                        `ORP_${hr}`
                                      ]
                                    )
                                  ),
                                  "Propionate"
                                )}px`,
                                borderTop: !comparedSampleRecord.chemicals?.[
                                  `ORP_${hr}`
                                ]
                                  ? ""
                                  : `dashed 2px ${CompareDecreasedColor}`,
                              }}
                            />
                          ) : null}
                        </div>
                        <div className="cell-value">
                          {sampleRecord.chemicals?.[`ORP_${hr}`]
                            ? getPropionate(
                                Number(sampleRecord.chemicals?.[`ORP_${hr}`])
                              )
                            : ""}
                        </div>
                        {[3, 6, 12, 24].some(
                          (eachHr) =>
                            sampleRecord.chemicals?.[`ORP_${eachHr}`] &&
                            comparedSampleRecord?.chemicals?.[`ORP_${eachHr}`]
                        ) ? (
                          <div
                            style={{
                              color:
                                (getPropionate(
                                  Number(sampleRecord.chemicals?.[`ORP_${hr}`])
                                ) || 0) -
                                  (getPropionate(
                                    Number(
                                      comparedSampleRecord?.chemicals?.[
                                        `ORP_${hr}`
                                      ]
                                    )
                                  ) || 0) >=
                                0
                                  ? CompareIncreasedColor
                                  : CompareDecreasedColor,
                              fontWeight: "600",
                              fontSize: "14px",
                              textAlign: "center",
                              padding: "0px 8px",
                            }}
                          >
                            {sampleRecord.chemicals?.[`ORP_${hr}`] &&
                            comparedSampleRecord?.chemicals?.[`ORP_${hr}`]
                              ? `${
                                  getPropionate(
                                    Number(
                                      sampleRecord.chemicals?.[`ORP_${hr}`]
                                    ) || 0
                                  ) -
                                    getPropionate(
                                      Number(
                                        comparedSampleRecord.chemicals?.[
                                          `ORP_${hr}`
                                        ]
                                      ) || 0
                                    ) >
                                  0
                                    ? "+"
                                    : ""
                                }${Number(
                                  (
                                    getPropionate(
                                      Number(
                                        sampleRecord.chemicals?.[`ORP_${hr}`]
                                      ) || 0
                                    ) -
                                    getPropionate(
                                      Number(
                                        comparedSampleRecord.chemicals?.[
                                          `ORP_${hr}`
                                        ]
                                      ) || 0
                                    )
                                  ).toFixed(2)
                                )}`
                              : ""}
                          </div>
                        ) : null}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell className="table-row-label">
                      <div className="table-row-label-title">Total VFA</div>
                      <div className="table-row-label-unit">(mol/kg DMI)</div>
                    </TableCell>
                    {[3, 6, 12, 24].map((hr) => (
                      <TableCell key={`tVFA-${hr}`}>
                        <div
                          className="cell-pillar"
                          style={{
                            position: "relative",
                            height: `${getGoodnessHeight(
                              getTotalVFA(
                                Number(sampleRecord.chemicals?.[`ORP_${hr}`])
                              ),
                              "TotalVFA"
                            )}px`,
                            backgroundColor: sampleRecord.chemicals?.[
                              `ORP_${hr}`
                            ]
                              ? getGoodnessColor(
                                  getTotalVFA(
                                    Number(
                                      sampleRecord.chemicals?.[`ORP_${hr}`]
                                    )
                                  ),
                                  "TotalVFA"
                                )
                              : undefined,
                          }}
                        >
                          {comparedSampleRecord &&
                          comparedSampleRecord.chemicals?.[`ORP_${hr}`] ? (
                            <div
                              style={{
                                position: "absolute",
                                bottom: "0px",
                                width: "100%",
                                height: `${getGoodnessHeight(
                                  getTotalVFA(
                                    Number(
                                      comparedSampleRecord.chemicals?.[
                                        `ORP_${hr}`
                                      ]
                                    )
                                  ),
                                  "TotalVFA"
                                )}px`,
                                borderTop: !comparedSampleRecord.chemicals?.[
                                  `ORP_${hr}`
                                ]
                                  ? ""
                                  : `dashed 2px ${CompareDecreasedColor}`,
                              }}
                            />
                          ) : null}
                        </div>
                        <div className="cell-value">
                          {sampleRecord.chemicals?.[`ORP_${hr}`]
                            ? getTotalVFA(
                                Number(sampleRecord.chemicals?.[`ORP_${hr}`])
                              )
                            : ""}
                        </div>
                        {[3, 6, 12, 24].some(
                          (eachHr) =>
                            sampleRecord.chemicals?.[`ORP_${eachHr}`] &&
                            comparedSampleRecord?.chemicals?.[`ORP_${eachHr}`]
                        ) ? (
                          <div
                            style={{
                              color:
                                (getTotalVFA(
                                  Number(sampleRecord.chemicals?.[`ORP_${hr}`])
                                ) || 0) -
                                  (getTotalVFA(
                                    Number(
                                      comparedSampleRecord?.chemicals?.[
                                        `ORP_${hr}`
                                      ]
                                    )
                                  ) || 0) >=
                                0
                                  ? CompareIncreasedColor
                                  : CompareDecreasedColor,
                              fontWeight: "600",
                              fontSize: "14px",
                              textAlign: "center",
                              padding: "0px 8px",
                            }}
                          >
                            {sampleRecord.chemicals?.[`ORP_${hr}`] &&
                            comparedSampleRecord?.chemicals?.[`ORP_${hr}`]
                              ? `${
                                  getTotalVFA(
                                    Number(
                                      sampleRecord.chemicals?.[`ORP_${hr}`]
                                    ) || 0
                                  ) -
                                    getTotalVFA(
                                      Number(
                                        comparedSampleRecord.chemicals?.[
                                          `ORP_${hr}`
                                        ]
                                      ) || 0
                                    ) >
                                  0
                                    ? "+"
                                    : ""
                                }${Number(
                                  (
                                    getTotalVFA(
                                      Number(
                                        sampleRecord.chemicals?.[`ORP_${hr}`]
                                      ) || 0
                                    ) -
                                    getTotalVFA(
                                      Number(
                                        comparedSampleRecord.chemicals?.[
                                          `ORP_${hr}`
                                        ]
                                      ) || 0
                                    )
                                  ).toFixed(5)
                                )}`
                              : ""}
                          </div>
                        ) : null}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
}
