/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import BalanceIcon from "@mui/icons-material/Balance";
import React from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { ISampleRecordPopulated } from "../../../../types/sample";
import {
  CircularProgress,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import ProgressButton from "../ProgressButton";
import GasReport2 from "./AnalysisReportVariations/GasReport2";
import SampleIdSingleAutocomplete from "../SampleIdSingleutocomplete";
import GasReport3 from "./AnalysisReportVariations/GasReport3";
import { showSnackbar } from "../../../../service/snackbar.service";
import sampleRecordService from "../../../../service/sampleRecord.service";

export default function GasAnalysisReportDialog({
  open,
  onClose,
  sampleRecord,
}: {
  sampleRecord: ISampleRecordPopulated;
  open: boolean;
  onClose: () => void;
}): React.ReactElement {
  const printRef = React.useRef<HTMLElement>();
  const [downloadingReport, setDownloadingReport] = React.useState(false);
  const [reportVariation, setReportVariation] = React.useState(
    !sampleRecord.reportType
      ? 1
      : sampleRecord.reportType?.toLowerCase()?.includes("dairy") &&
        sampleRecord.reportType?.toLowerCase()?.includes("carbon")
      ? 1
      : sampleRecord.reportType?.toLowerCase()?.includes("dairy") &&
        !sampleRecord.reportType?.toLowerCase()?.includes("carbon")
      ? 2
      : sampleRecord.reportType?.toLowerCase()?.includes("beef") &&
        sampleRecord.reportType?.toLowerCase()?.includes("carbon")
      ? 3
      : 1
  );
  const [compareSampleRecord, setCompareSampleRecord] =
    React.useState<ISampleRecordPopulated>();
  const [loading, setLoading] = React.useState(true);

  const handleGeneratePdf = async () => {
    try {
      const element = printRef.current;
      if (!element || !sampleRecord.sampleNumber) return;
      setDownloadingReport(true);
      const canvas = await html2canvas(element, { scale: 1 });
      const data = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgProperties = pdf.getImageProperties(data);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      const scaledHeight = imgHeight > pdfHeight ? pdfHeight : imgHeight; // Ensure the image height does not exceed the page height
      pdf.addImage(data, "PNG", 0, 0, pdfWidth, scaledHeight);
      pdf.save(`${sampleRecord.sampleNumber}_gas_report.pdf`);
      setDownloadingReport(false);
    } catch (error) {
      showSnackbar("danger", "Error", "Error generating PDF");
    } finally {
      setDownloadingReport(false);
    }
  };

  const handleMarkAsReportMade = async () => {
    setLoading(true);
    await sampleRecordService.markAsReportMade([sampleRecord.uid]);
    setLoading(false);
  };

  React.useEffect(() => {
    handleMarkAsReportMade();
  }, []);

  return (
    <>
      <Dialog open={open} maxWidth="md" scroll="paper">
        <DialogTitle sx={{ py: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6">{"Gas Analysis Report"}</Typography>
            <ProgressButton
              inProgress={downloadingReport}
              startIcon={<FileDownloadIcon />}
              variant="outlined"
              size="small"
              onClick={() => handleGeneratePdf()}
              sx={{ ml: 4 }}
              title="PDF"
            />
            <div style={{ flex: 1 }} />
            <BalanceIcon
              sx={{ fontSize: "31px", mr: "8px" }}
              color={compareSampleRecord ? "primary" : "disabled"}
            />
            <div style={{ width: "250px", marginRight: "8px" }}>
              <SampleIdSingleAutocomplete
                placeholder="Search sample number"
                label="Compared to"
                value={compareSampleRecord}
                onSelectValue={setCompareSampleRecord}
                includeChemicals
              />
            </div>
            <TextField
              select
              label={""}
              value={reportVariation}
              size="small"
              onChange={(e) => {
                setReportVariation(Number(e.target.value));
              }}
              sx={{
                minWidth: "200px",
                mr: 1,
                "& input": { padding: "5px 10px 5px 0px" },
              }}
            >
              <MenuItem value={1}>Dairy Carbon</MenuItem>
              <MenuItem value={2}>Dairy</MenuItem>
              <MenuItem value={3}>Beef Carbon</MenuItem>
            </TextField>

            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "1200px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box p={2} ref={printRef}>
              {reportVariation == 1 ? (
                <GasReport2
                  sampleRecord={sampleRecord}
                  comparedSampleRecord={compareSampleRecord}
                />
              ) : reportVariation == 2 ? (
                <GasReport3
                  sampleRecord={sampleRecord}
                  comparedSampleRecord={compareSampleRecord}
                />
              ) : (
                <GasReport3
                  sampleRecord={sampleRecord}
                  comparedSampleRecord={compareSampleRecord}
                />
              )}
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
