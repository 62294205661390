/* eslint-disable @typescript-eslint/no-unused-vars */

import * as React from "react";
import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import { ISampleRecordPopulated } from "../../../../../types/sample";
import Grid from "@mui/material/Grid";
import AnalysisValuesDynamicTable from "../Tables/AnalysisValuesDynamicTable";
import {
  DigestionRatesTableHeaders,
  DigestionRatesTableRowsData,
  DmTableHeaders,
  DmTableRowsData,
  TimeToMaxRatesTableHeaders,
  TimeToMaxRatesTableRowsData,
} from "../Tables/TablesData";
import RelativePoolContributionTable from "../Tables/RelativePoolContributionTable";
import {
  getBiggestQuadrant,
  getFastHrMaxRateHours,
  getSlowHrMaxRateHours,
  SliderInfo,
  SummerySection,
} from "../ReportUtils";
import AnalysisSlider from "../Sliders/AnalysisSlider";
import {
  AnalysisValueColorRanges,
  AnalysisValueRanges,
} from "../AnalysisConstants";
import { GasAnalysisConclusionsByQuadrant } from "../../../../../helpers/gasAnalysisReport";
import GasAnalysisGraph2 from "../AnalysisGraphVariations/AnalysisGraph2";
import SliderGroup from "../Sliders/SliderGroup";
import KeyValueBlock from "../KeyValueBlock";

const fastSlow = ["Fast", "Slow"];

export default function GasReport2({
  sampleRecord,
  comparedSampleRecord,
}: {
  sampleRecord: ISampleRecordPopulated;
  comparedSampleRecord?: ISampleRecordPopulated;
}) {
  const FastHrMaxRateHours = getFastHrMaxRateHours(sampleRecord);
  const SlowHrMaxRateHours = getSlowHrMaxRateHours(sampleRecord);
  const quadrant = getBiggestQuadrant(sampleRecord);

  return (
    <Box>
      <Box
        sx={{
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <img
          src="fermentrics-logo.png"
          style={{
            maxHeight: "58px",
            width: "auto",
          }}
        />
        <div style={{ flex: 1 }} />
        {sampleRecord.description ? (
          <div
            style={{
              fontSize: "14px",
              maxWidth: "200px",
              marginRight: "16px",
            }}
          >
            <div style={{ marginBottom: "3px", fontWeight: "500" }}>
              Description:
            </div>
            <div>{sampleRecord.description}</div>
          </div>
        ) : null}

        <div
          style={{
            fontSize: "14px",
            marginRight: "16px",
          }}
        >
          <div style={{ marginBottom: "3px" }}>
            <b>Lab: </b> {` ${sampleRecord.company?.name}`}
          </div>
          <div style={{ marginBottom: "3px" }}>
            <b>Client: </b>
            {` ${sampleRecord.sampleClient}`}
          </div>
          <div style={{ marginBottom: "3px" }}>
            <b>Farm: </b>
            {` ${sampleRecord.sampleSource}`}
          </div>
        </div>
        <div
          style={{
            fontSize: "14px",
          }}
        >
          <div style={{ marginBottom: "3px" }}>
            <b>SSID:</b>
            {` ${sampleRecord.uid}`}
          </div>
          <div style={{ marginBottom: "3px" }}>
            <b>Desc:</b>
            {` ${sampleRecord.companyUid}`}
          </div>
          <div>
            <b>Origin:</b>
            {` ${sampleRecord.sampleNumber}`}
          </div>
        </div>
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <GasAnalysisGraph2
            key={`${comparedSampleRecord ? 1 : 0}compare`}
            sampleRecord={sampleRecord}
            comparedSampleRecord={comparedSampleRecord}
            hideHourlyMatrix={true}
            hideKeyValueBlocks={true}
          />
        </Grid>
      </Grid>
      <Grid container mt={3}>
        <Grid item xs={12} md={8}>
          <SliderGroup
            sampleRecord={sampleRecord}
            comparedSampleRecord={comparedSampleRecord}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <div style={{ marginTop: "-10px", marginBottom: "12px" }}>
            <KeyValueBlock
              sampleRecord={sampleRecord}
              comparedSampleRecord={comparedSampleRecord}
            />
          </div>
          <div
            style={{
              border: "solid 1px #d1d1d1",
              borderRadius: "8px",
              overflow: "hidden",
              marginBottom: "16px",
            }}
          >
            <AnalysisValuesDynamicTable
              cellPaddingY={3}
              comparedSampleRecord={comparedSampleRecord}
              sampleRecord={sampleRecord}
              rowsData={DmTableRowsData}
              headers={DmTableHeaders}
            />
          </div>
        </Grid>
      </Grid>
      <Box mt={"-12px"}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box>
              <Box
                sx={{
                  fontWeight: "500",
                  background: "#f7f6df",
                  padding: "12px 16px",
                  borderRadius: "5px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {quadrant != null && !isNaN(quadrant) ? (
                    <div
                      style={{
                        marginRight: "4px",
                        background: "#e2e0a7",
                        padding: "0px 4px",
                        borderRadius: "3px",
                        display: "flex",
                      }}
                    >
                      {`Q${quadrant + 1}`}
                      {quadrant == 3 ? (
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              fontSize: "13px",
                              marginLeft: "6px",
                              marginTop: "-2px",
                            }}
                          >
                            y
                          </div>
                          <div
                            style={{
                              fontStyle: "italic",
                              fontFamily: "auto",
                              fontSize: "13px",
                              marginLeft: "2px",
                              marginTop: "-2px",
                            }}
                          >
                            f
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  {quadrant == null ? "Quadrant undefined" : ``}:{" "}
                  {quadrant == null
                    ? ""
                    : `${fastSlow[Math.floor(quadrant / 2)]} "fast pool" and ${
                        fastSlow[quadrant % 2]
                      } "slow pool"`}
                </div>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <SummerySection>
              <div className="summery-title">TMR rates</div>
              <div className="summery-content">
                {quadrant == null
                  ? null
                  : GasAnalysisConclusionsByQuadrant.tmrRatesByQuadrant[
                      quadrant
                    ].map((c, i) => (
                      <div className="summery-item" key={i}>
                        <div className="summery-item-dot" />
                        <div className="summery-item-content">{c}</div>
                      </div>
                    ))}
              </div>
            </SummerySection>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <SummerySection>
              <div className="summery-title">On-Farm Observations</div>
              <div className="summery-content">
                {quadrant == null
                  ? null
                  : GasAnalysisConclusionsByQuadrant.onFarmObservations[
                      quadrant
                    ].map((c, i) => (
                      <div className="summery-item" key={i}>
                        <div className="summery-item-dot" />
                        <div className="summery-item-content">{c}</div>
                      </div>
                    ))}
              </div>
            </SummerySection>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <SummerySection>
              <div className="summery-title">Actions to Consider</div>
              <div className="summery-content">
                {quadrant == null
                  ? null
                  : GasAnalysisConclusionsByQuadrant.actionsToConsider[
                      quadrant
                    ].map((c, i) => (
                      <div className="summery-item" key={i}>
                        <div className="summery-item-dot" />
                        <div className="summery-item-content">{c}</div>
                      </div>
                    ))}
              </div>
            </SummerySection>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 2, fontSize: "10px" }}>
        <div style={{ textAlign: "center" }}>
          Results are based on fermentation of the assumed homogeneous
          representative sample. Fermentrics Technologies Inc. is not
          responsible for decisions made using these results.
        </div>

        <div style={{ textAlign: "center", marginTop: "5px" }}>
          Fermentrics © 2024 All Rights Reserved | Patent Pending
        </div>
      </Box>
    </Box>
  );
}
